import {createApp} from 'vue'
import App from './App.vue'
import router from './router/Index'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import configFormatter from './lib/Formatter'
import Http from "./lib/Http";
import OAuth from './lib/OAuth2';
import mitt from 'mitt';




if (location.pathname === "/" && Http.getQueryParameter('code')) {
    const oauth = new OAuth(process.env.VUE_APP_OAUTH_URL, process.env.VUE_APP_OAUTH_CLIENT_ID, process.env.VUE_APP_OAUTH_REDIRECT_URL);
    oauth.requestToken();
    location.href = "/";
} else {
    const app = createApp(App);
    app.config.globalProperties.emitter = mitt();
    app.use(router);
    app.use(ElementPlus);
    app.mount('#app');
    configFormatter(app);
}

