<template>
  <head-panel>
    <template v-slot:body>Images</template>
  </head-panel>

  <div class="wrapper">

    <table class="table">
      <thead>
      <th scope="col">#</th>
      <th scope="col">image</th>
      <th scope="col">Filename</th>
      <th scope="col">Size1</th>
      <th scope="col"></th>
      </thead>
      <tbody>

      <template v-for="(image, index) of images" :key="index">
        <tr>
          <th scope="row">{{ index + 1 }}</th>
          <td><img :id="'img'+index" :src="img('img'+index, '/files/'+bucketId+'/origin/'+image.filename)" width="30">
          </td>
          <td>{{ image.filename }}</td>
          <td>{{ this.$filters.num(image.size / 1024) }} Kb</td>
          <td class="actions">
            <a @click="show(image)">Detail</a>
            <a @click="deleteConfirm(image.id)">Remove</a>
          </td>
        </tr>
        <tr v-if="detail && detail.id === image.id">
          <td colspan="5">


            <strong>ID</strong>
            <div class="mb-2">{{ detail.id }}</div>

            <strong>Filename</strong>
            <div class="mb-2">{{ detail.filename }}</div>

            <strong>Size</strong>
            <div class="mb-2">{{ this.$filters.num(detail.size / 1024) }} Kb</div>

            <strong>Path</strong>
            <div class="mb-2">{{ detail.path }}</div>

            <strong>Storage</strong>
            <div class="mb-2">{{ detail.storage }}</div>

            <strong>Created</strong>
            <div class="mb-2">{{ detail.createdAt }}</div>

          </td>
        </tr>
      </template>

      <tr v-if="!images.length">
        <td colspan="5">No items</td>
      </tr>


      </tbody>
    </table>

    <Pagination :current-page="pagination.page" :pager-len="pagination.size" :total-pages="pagination.total"/>

  </div>
  <confirm-dialogue ref="confirmDialogue"/>
</template>

<script>

import Http from "../../lib/Http";
import Pagination from '../../components/Pagination'
import HeadPanel from "../../components/HeadPanel";
import ConfirmDialogue from '../../components/ConfirmDialog.vue'
import OAuth from "../../lib/OAuth2";

const oauth = new OAuth(process.env.VUE_APP_OAUTH_URL, process.env.VUE_APP_OAUTH_CLIENT_ID, process.env.VUE_APP_OAUTH_REDIRECT_URL);

export default {
  name: "Media",
  components: {HeadPanel, Pagination, ConfirmDialogue},
  created() {
    this.fetch();
  },
  watch: {
    $route() {
      this.fetch();
    }
  },
  data() {
    return {
      bucketId: this.$route.params.id,
      detail: null,
      images: [],
      pagination: {
        page: 0,
        total: 0,
        size: 0
      }
    }
  },
  methods: {
    fetch: function () {

      if (this.$route.params.id === undefined)
        return;

      let url = '/v1/media/' + this.$route.params.id;

      if (this.$route.query.page !== undefined)
        url += '?page=' + this.$route.query.page;

      Http.get(url)
          .then((res) => {
            this.images = res.content;
            this.pagination.page = res.origin.headers.get("X-Current-Page") * 1;
            this.pagination.total = res.origin.headers.get("X-Page-Count") * 1;
            this.pagination.size = res.origin.headers.get("x-page-size") * 1;
          });
    },
    deleteConfirm: function (id) {

      this.$refs.confirmDialogue.show({
        title: 'Confirmation',
        message: 'Are you sure you want to delete image?',
        okButton: 'Yes',
        cancelButton: 'no',
      }).then((ok) => {
        if (ok)
          Http
              .del('/v1/media/' + id)
              .then(() => this.fetch())
      });
    },
    show: function (item) {
      this.detail = item;
    },
    img: function (id, path) {
      let xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onreadystatechange = function () {
        if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
          let img = document.getElementById(id);
          img.src = URL.createObjectURL(xhr.response);
        }
      };
      xhr.open('GET', path, true);
      xhr.setRequestHeader('Authorization', 'Bearer ' + oauth.getToken().accessToken);
      xhr.send();

      return '/img/no-image-icon.png';
    }
  }
}
</script>

<style scoped>

</style>