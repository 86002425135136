<template>
  <head-panel>
    <template v-slot:body>Keys
      <router-link to="/key/create">
        <button class="btn btn-primary btn-sm btn-create-top ms-3 " role="button">
          Add new key
        </button>
      </router-link>

    </template>
  </head-panel>

  <div class="wrapper">

    <table class="table">
      <thead>
      <th scope="col">#</th>
      <th scope="col">Id</th>
      <th scope="col">Secret</th>
      <th scope="col">Description</th>
      <th scope="col">Created</th>
      <th scope="col"></th>
      </thead>
      <tbody>
      <tr v-for="(key, index) of keys" :key="index">
        <th scope="row">{{ index + 1 }}</th>
        <td>{{ key.id }}</td>
        <td>

          <span v-if="key.id === showKeyId">{{ key.secret }}</span>
          <span v-else>
              <svg @click="showKeyId = key.id" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                   fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
            <path
                d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
            <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
          </svg>
          </span>


        </td>
        <td>{{ key.description }}</td>
        <td>{{ key.createdAt }}</td>
        <td class="actions">
          <router-link :to="{ path: '/key/update/'+key.id}" class=" ms-3">
            Edit
          </router-link>
          <a @click="deleteConfirm(key.id)">Remove</a>
        </td>
      </tr>
      <tr v-if="!keys.length">
        <td colspan="6">No items</td>
      </tr>
      </tbody>
    </table>

  </div>
  <confirm-dialogue ref="confirmDialogue"/>
</template>

<script>

import Http from "../../lib/Http";
import HeadPanel from "../../components/HeadPanel";
import ConfirmDialogue from '../../components/ConfirmDialog.vue'

export default {
  name: "AccessKey",
  components: {HeadPanel, ConfirmDialogue},
  created() {
    this.fetch();
  },
  data() {
    return {
      showKeyId: null,
      keys: [],
    }
  },
  methods: {
    fetch: function () {

      let url = '/v1/key';

      Http.get(url)
          .then((res) => {
            this.keys = res.content;
          });
    },
    generate: function () {
      let url = '/v1/key/generate';
      Http.post(url)
          .then((res) => {
            this.keys = res.content;
          })
          .then(() => this.fetch());
    },
    deleteConfirm: function (id) {

      this.$refs.confirmDialogue.show({
        title: 'Confirmation',
        message: 'Are you sure you want to delete key?',
        okButton: 'Yes',
        cancelButton: 'no',
      }).then((ok) => {
        if (ok)
          Http
              .del('/v1/key/' + id)
              .then(() => this.fetch())
      });
    }
  }
}
</script>

<style scoped>
.bi-eye {
  cursor: pointer;
}
</style>