<template>
  <head-panel>
    <template v-slot:body>Preset
      <router-link to="/preset/create">
        <button class="btn btn-primary btn-sm btn-create-top ms-3 " role="button">
          Add new preset
        </button>
      </router-link>
    </template>
  </head-panel>


  <div class="wrapper">

    <table class="table">
      <thead >
        <th scope="col">#</th>
        <th scope="col">Name</th>
        <th scope="col">Mode</th>
        <th scope="col">Quality</th>
        <th scope="col">Resolution</th>
        <th scope="col"></th>
      </thead>
      <tbody>
      <tr v-for="(preset, index) of presets" :key="index">
        <th scope="row">{{index+1}}</th>
        <td>{{preset.name}}</td>
        <td>{{preset.mode}}</td>
        <td>{{preset.quality}}</td>
        <td>{{preset.width}}x{{preset.height}}</td>
        <td class="actions">
          <router-link :to="{ path: '/preset/update/'+preset.id}" class="">
            Edit
          </router-link>
          <a @click="deleteConfirm(preset.id)" class=" ms-3">
            Delete
          </a>
        </td>
      </tr>

      <tr v-if="!presets.length">
        <td colspan="6">No items</td>
      </tr>

      </tbody>
    </table>

    <Pagination v-if="pagination.total" :current-page="pagination.page" :pager-len="pagination.size" :total-pages="pagination.total"/>

  </div>
  <confirm-dialogue ref="confirmDialogue"/>
</template>

<script>

import Http from "../../lib/Http";
import Pagination from '../../components/Pagination'
import HeadPanel from "../../components/HeadPanel";
import ConfirmDialogue from '../../components/ConfirmDialog.vue'

export default {
  name: "Preset",
  components: {HeadPanel, Pagination, ConfirmDialogue},
  created() {
    this.fetch();
  },
  watch:{
    $route (){
      this.fetch();
    }
  },
  data() {
    return {
      presets: [],
      pagination: {
        page: 0,
        total: 0,
        size: 0
      }
    }
  },
  methods: {
    fetch: function () {

      let url = '/v1/preset';

      if (this.$route.query.page !== undefined)
        url += '?page=' + this.$route.query.page;

      Http.get(url)
          .then((res) => {
            this.presets = res.content;
            this.pagination.page = res.origin.headers.get("X-Current-Page") * 1;
            this.pagination.total = res.origin.headers.get("X-Page-Count") * 1;
            this.pagination.size = res.origin.headers.get("x-page-size") * 1;
          });
    },
    deleteConfirm: function (id) {

      this.$refs.confirmDialogue.show({
        title: 'Confirmation',
        message: 'Are you sure you want to delete preset?',
        okButton: 'Yes',
        cancelButton: 'no',
      }).then((ok) => {
        if (ok)
          Http
              .del('/v1/preset/' + id)
              .then(() => this.fetch())
      });
    }
  }
}
</script>

<style scoped>

</style>