<template>
  <div :key="$route.fullPath" v-if="totalPages>0">
    <ul class="pagination">
      <li class="page-item" v-if="currentPage > 0">
        <router-link v-bind:to="urlTo(currentPage-1)" aria-label="Previous" class="page-link">
          <span aria-hidden="true">
            <svg viewBox="0 0 24 24" fill="currentColor" class="transform180 icon-0-2-31"><path
                d="M9.79461 17.2946C9.40534 16.9053 9.405 16.2743 9.79384 15.8846L13.67 12L9.79384 8.11538C9.405 7.72569 9.40534 7.09466 9.79461 6.70538C10.1842 6.31581 10.8158 6.31581 11.2054 6.70538L16.5 12L11.2054 17.2946C10.8158 17.6842 10.1842 17.6842 9.79461 17.2946Z"></path></svg>
          </span>
        </router-link>
      </li>
      <li class="page-item disabled" v-else>
        <span aria-hidden="true" class="page-link">
          <svg viewBox="0 0 24 24" fill="currentColor" class="transform180 icon-0-2-31"><path
              d="M9.79461 17.2946C9.40534 16.9053 9.405 16.2743 9.79384 15.8846L13.67 12L9.79384 8.11538C9.405 7.72569 9.40534 7.09466 9.79461 6.70538C10.1842 6.31581 10.8158 6.31581 11.2054 6.70538L16.5 12L11.2054 17.2946C10.8158 17.6842 10.1842 17.6842 9.79461 17.2946Z"></path></svg>
        </span>
      </li>
      <li v-for="n in pages()" :key="n" :class="{active: n === Number.parseInt(currentPage)}">
        <router-link v-bind:to="urlTo(n)" v-if="n !== currentPage" class="page-link">{{ n + 1 }}</router-link>
        <span class="page-link" v-else>{{ n + 1 }}</span>
      </li>
      <li class="page-item" v-if="currentPage < totalPages-1">
        <router-link v-bind:to="urlTo(currentPage+1)" aria-label="Next" class="page-link">
          <span aria-hidden="true">
          <svg viewBox="0 0 24 24" fill="currentColor" class="icon-0-2-31"><path
              d="M9.79461 17.2946C9.40534 16.9053 9.405 16.2743 9.79384 15.8846L13.67 12L9.79384 8.11538C9.405 7.72569 9.40534 7.09466 9.79461 6.70538C10.1842 6.31581 10.8158 6.31581 11.2054 6.70538L16.5 12L11.2054 17.2946C10.8158 17.6842 10.1842 17.6842 9.79461 17.2946Z"></path></svg>
        </span>
        </router-link>
      </li>
      <li class="page-item disabled" v-else>
        <span aria-hidden="true" class="page-link">
          <svg viewBox="0 0 24 24" fill="currentColor" class="icon-0-2-31"><path
              d="M9.79461 17.2946C9.40534 16.9053 9.405 16.2743 9.79384 15.8846L13.67 12L9.79384 8.11538C9.405 7.72569 9.40534 7.09466 9.79461 6.70538C10.1842 6.31581 10.8158 6.31581 11.2054 6.70538L16.5 12L11.2054 17.2946C10.8158 17.6842 10.1842 17.6842 9.79461 17.2946Z"></path></svg>
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    currentPage: {
      type: Number,
      default: 1
    },
    totalPages: {
      type: Number,
      required: true
    },
    pagerLen: {
      type: Number,
      default: 10
    },
  },
  methods: {
    pages: function () {
      let pager = [];
      let start = Math.max(this.currentPage - 5, 0);
      let end = Math.min(start + this.pagerLen, this.totalPages);

      for (let i = start; i < end; i++) {
        pager.push(i);
      }

      return pager;
    },
    urlTo: function (page) {

      if(page === 0)
        return this._addOrUpdateUrlParam("page", null);

      return this._addOrUpdateUrlParam("page", page);
    },
    _addOrUpdateUrlParam: function (name, value) {
      let href = this.$route.fullPath;

      let regex = new RegExp("[&\\?]" + name + "=");
      if (regex.test(href)) {
        regex = new RegExp("([&\\?])" + name + "=\\d+");

        if(value == null)
          return href.replace(regex, "");
        return href.replace(regex, "$1" + name + "=" + value);
      } else {
        if (href.indexOf("?") > -1)
          return href + "&" + name + "=" + value;
        else
          return href + "?" + name + "=" + value;
      }
    }
  }
}
</script>

<style scoped>

.pagination{
  margin-left: 40px;
  margin-top: 20px;
}

.page-link{
  width: 36px;
  text-align: center;
}

li.active .page-link{
  background-color: #248AFDFF;
  color: #fff;
  border-color: #248AFDFF;
}

svg {
  width: 18px;
  height: 18px;
  display: inline-block;
  flex-shrink: 0;
  user-select: none;
}

.transform180 {
  transform: rotate(180deg);
}

</style>