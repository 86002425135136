<template>
  <head-panel>
    <template v-slot:body></template>
  </head-panel>
  <div class="wrapper">

    <div class="honeycomb">
      <div class="cell" v-for="(node, index) in nodes" :key="node">
        <div class="cell-inner">
          <a class="content" v-bind:class="'cell-'+(index+1)+' '+(node.id?'':'empty')" href="#">
            <span>{{ node.name }}</span>
          </a>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import HeadPanel from "../components/HeadPanel";
import Http from "../lib/Http";

export default {
  name: "Index",
  components: {HeadPanel},
  mounted() {
    this.fetch();
    this.emitter.on("toggle-sidebar", () => {
      setTimeout(() => this.createHoneycomb(), 200);
    });
    window.addEventListener('resize', this.createHoneycomb, false);
  },
  data() {
    return {
      minItems: 30,
      nodes: []
    }
  },
  methods: {
    fetch: function () {
      let url = '/v1/node';
      Http.get(url)
          .then(res => {

            this.nodes = res.content

            for (let i = this.nodes.length; i < this.minItems; i++) {
              this.nodes.push({
                id: null,
                name: null,
              });
            }


          })
          .then(() => this.createHoneycomb());
    },

    createHoneycomb: function () {
      let isOddLen = function (i) {
        return i % 2;
      }
      let cells = document.querySelectorAll('.honeycomb .cell');
      let lastLeft = -500;
      let is_odd = false;
      let rows = [[]];
      let j = 0;

      for (let i = 0; i < cells.length; i++) {
        let pos = cells[i].getBoundingClientRect();
        if (pos.left < lastLeft) {
          is_odd = !is_odd;
          j++;
          rows[j] = [];
        }
        rows[j].push(cells[i]);
        lastLeft = pos.left;

        if (is_odd) {
          cells[i].classList.add('odd-row');
        } else {
          cells[i].classList.remove('odd-row');
        }
      }
      for (let i = 0; i < rows.length - 1; i++) {
        if (isOddLen(rows[i].length) !== isOddLen(rows[i + 1].length)) {
          for (let j = 0; j < rows[i + 1].length; j++) {
            rows[i + 1][j].classList.toggle('odd-row');
          }
        }
      }
    }
  },
}


</script>

<style scoped lang="scss">
$max_width: 700px;
$cell_width: 150px;
$cell_spacing: 11px;
$v_offset: 0.32*$cell_width - 0.5*$cell_spacing;
$h_offset: 0.25*$cell_width + 0.25*$cell_spacing;


.honeycomb {
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 90%;
  padding: 20px 45px;
  position: relative;

  &, *, *:before, *:after {
    box-sizing: border-box;
  }

  .cell {
    position: relative;
    display: block;
    vertical-align: center;
    width: $cell_width;
    height: 1.5*$cell_width;
    margin: -1*$v_offset $cell_spacing/2 -1*$v_offset $cell_spacing/2;
    text-decoration: none;
    overflow: hidden;
    transform: rotate(-120deg);
    left: -1*( $h_offset );

    &.odd-row {
      left: $h_offset;
    }

    .cell-inner {
      overflow: hidden;
      width: 100%;
      height: 100%;
      transform: rotate(60deg);

      .content {
        display: block;
        width: 100%;
        height: 100%;
        transform: rotate(60deg);
        background-color: #248afd;
        color: #fff;

        &.empty {
          background-color: #c9c9c9;
        }

        &:hover {
          background-color: #000;
          color: #fff;
        }
      }
    }

    &:last-child {
      margin-right: 0;
    }

    &:nth-child(2n) {
      .cell-inner .content {
        background-color: #248afd;

        &.empty {
          background-color: #e0dfdf;
        }

        &:hover {
          background-color: #000;
          color: #fff;
        }
      }
    }

    span {
      font-weight: 500;
      position: absolute;
      text-align: center;
      width: 100px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}


</style>