<template>
  <head-panel>
    <template v-slot:body>Bucket
      <router-link to="/bucket/create">
        <button class="btn btn-primary btn-sm btn-create-top ms-3 " role="button">
          Add new bucket
        </button>
      </router-link>
    </template>
  </head-panel>

  <div class="wrapper">

    <table class="table">
      <thead>
        <th scope="col">#</th>
        <th scope="col">id</th>
        <th scope="col">Name</th>
        <th scope="col">Items</th>
        <th scope="col">Total size</th>
        <th scope="col">Origin size</th>
        <th scope="col">Cache size</th>
        <th scope="col"></th>
      </thead>
      <tbody>
      <tr v-for="(bucket, index) of buckets" :key="index">
        <th scope="row">{{index+1}}</th>
        <td>{{bucket.id}}</td>
        <td>{{bucket.name}}</td>
        <td>
          {{bucket.bucketStatistic ? bucket.bucketStatistic.mediaCount : 0}}
          <a @click="clearConfirm(bucket.id)" class=" ms-3">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
              <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
              <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
            </svg>
          </a>
        </td>
        <td>
          {{ bucket.bucketStatistic ? this.$filters.num(bucket.bucketStatistic.storageStatistic.totalSize / (1024 * 1024) ): 0 }} Mb
          <a @click="updateStatisticConfirm(bucket.id)" class=" ms-3">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-repeat" viewBox="0 0 16 16">
              <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z"/>
              <path fill-rule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"/>
            </svg>
          </a>
        </td>
        <td>{{ bucket.bucketStatistic ? this.$filters.num(bucket.bucketStatistic.storageStatistic.originSize / (1024 * 1024) ): 0 }} Mb</td>
        <td>
          {{ bucket.bucketStatistic ? this.$filters.num(bucket.bucketStatistic.storageStatistic.cacheSize / (1024 * 1024) ): 0 }} Mb
          <a @click="clearCacheConfirm(bucket.id)" class=" ms-3">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
              <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
              <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
            </svg>
          </a>
        </td>
        <td class="actions">
          <router-link :to="{ path: '/media/'+bucket.id}" class="">
            View
          </router-link>
          <router-link :to="{ path: '/bucket/update/'+bucket.id}" class=" ms-3">
            Edit
          </router-link>
          <a @click="deleteConfirm(bucket.id)" class=" ms-3">
            Delete
          </a>
        </td>
      </tr>

      <tr v-if="!buckets.length">
        <td colspan="8">No items</td>
      </tr>

      </tbody>
    </table>

    <Pagination v-if="pagination.total" :current-page="pagination.page" :pager-len="pagination.size" :total-pages="pagination.total"/>

  </div>
  <confirm-dialogue ref="confirmDialogue"/>
</template>

<script>

import Http from "../../lib/Http";
import Pagination from '../../components/Pagination'
import HeadPanel from "../../components/HeadPanel";
import ConfirmDialogue from '../../components/ConfirmDialog.vue'

export default {
  name: "Index",
  components: {HeadPanel, Pagination, ConfirmDialogue},
  created() {
    this.fetch();
  },
  watch:{
    $route (){
      this.fetch();
    }
  },
  data() {
    return {
      buckets: [],
      pagination: {
        page: 0,
        total: 0,
        size: 0
      }
    }
  },
  methods: {
    fetch: function () {

      let url = '/v1/bucket';

      if (this.$route.query.page !== undefined)
        url += '?page=' + this.$route.query.page;


      Http.get(url)
          .then((res) => {
            this.buckets = res.content;
            this.pagination.page = res.origin.headers.get("X-Current-Page") * 1;
            this.pagination.total = res.origin.headers.get("X-Page-Count") * 1;
            this.pagination.size = res.origin.headers.get("x-page-size") * 1;
          });
    },
    updateStatisticConfirm:function (id){

      this.$refs.confirmDialogue.show({
        title: 'Confirmation',
        message: 'Are you sure you want update bucket statistic?',
        okButton: 'Yes',
        cancelButton: 'no',
      }).then((ok) => {
        if (ok)
          Http
              .post('/v1/bucket/' + id +'/update-statistic')
              .then(() => this.fetch())
      });
    },
    clearConfirm: function (id) {

      this.$refs.confirmDialogue.show({
        title: 'Confirmation',
        message: 'Are you sure you want clear bucket?',
        okButton: 'Yes',
        cancelButton: 'no',
      }).then((ok) => {
        if (ok)
          Http
              .post('/v1/bucket/' + id +'/clear')
              .then(() => this.fetch())
      });
    },
    clearCacheConfirm: function (id) {

      this.$refs.confirmDialogue.show({
        title: 'Confirmation',
        message: 'Are you sure you want clear bucket cache?',
        okButton: 'Yes',
        cancelButton: 'no',
      }).then((ok) => {
        if (ok)
          Http
              .post('/v1/bucket/' + id +'/clear-cache')
              .then(() => this.fetch())
      });
    },
    deleteConfirm: function (id) {

      this.$refs.confirmDialogue.show({
        title: 'Confirmation',
        message: 'Are you sure you want to delete bucket?',
        okButton: 'Yes',
        cancelButton: 'no',
      }).then((ok) => {
        if (ok)
          Http
              .del('/v1/bucket/' + id)
              .then(() => this.fetch())
      });
    }
  }
}
</script>

<style scoped>

</style>