import moment from "moment";

export default function config(app) {

    app.config.globalProperties.$filters = {
        dateTime(value) {
            return moment(String(value)).format('YYYY-MM-DD HH:mm:ss');
        },

        status(statue) {
            const labels = {
                new: 'Новий',
                processing: 'В обробці',
                complete: 'Виконаний',
                cancel: 'Скасований'
            };
            return labels[statue];
        },

        num(value, precis) {

            if (!precis)
                precis = 2;

            const precisNum = Math.pow(10, precis);
            return Math.round(value * precisNum) / precisNum;

        },
    }

}