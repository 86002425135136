import { createRouter, createWebHashHistory} from "vue-router";
import Sidebar from '../components/Sidebar.vue'
import Index from "../views/Index"
import BucketForm from "../views/bucket/Form";
import AccessKeyIndex from "../views/key/Index";
import AccessKeyForm from "../views/key/Form";
import BucketIndex from "../views/bucket/Index";
import PresetForm from "../views/preset/Form";
import PresetIndex from "../views/preset/Index";
import MediaIndex from "../views/media/Index";

const routes = [
    {
        path: "/",
        name: "Index",
        components: {
            default: Index,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/key",
        name: "AccessKey",
        components: {
            default: AccessKeyIndex,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/key/create",
        name: "AccessKeyCreate",
        components: {
            default: AccessKeyForm,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/key/update/:id",
        name: "AccessKeyUpdate",
        components: {
            default: AccessKeyForm,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/bucket",
        name: "Bucket",
        components: {
            default: BucketIndex,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/bucket/create",
        name: "Bucket create",
        components: {
            default: BucketForm,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/bucket/update/:id",
        name: "Bucket update",
        components: {
            default: BucketForm,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/preset",
        name: "Preset",
        components: {
            default: PresetIndex,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/preset/create",
        name: "Preset create",
        components: {
            default: PresetForm,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/preset/update/:id",
        name: "Preset update",
        components: {
            default: PresetForm,
            LeftSidebar: Sidebar
        }
    },
    {
        path: "/media/:id",
        name: "Media",
        components: {
            default: MediaIndex,
            LeftSidebar: Sidebar
        }
    }
];

const router = createRouter({
    history: createWebHashHistory(),
    // history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router