<template>
  <head-panel>
    <template v-slot:body>Preset
      <router-link to="/preset">
        <button class="btn btn-secondary btn-sm btn-create-top ms-3" role="button">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
               class="bi bi-arrow-left-short" viewBox="0 0 16 16">
            <path fill-rule="evenodd"
                  d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
          </svg>
          Back
        </button>
      </router-link>
    </template>
  </head-panel>

  <div class="wrapper">


    <div class="alert alert-danger" v-if="errors.length">
      <div v-for="error of errors" :key="error">{{ error.message }}</div>
    </div>
    <form @submit.prevent="submit">


      <div class="row ms-2 mb-4">
        <div class="col-xl-3">Name</div>
        <div class="col-lg-8">
          <input type="text"
                 name="name"
                 class="form-control"
                 :class="{ 'is-invalid' : v$.form.name.$error}"
                 v-model="form.name">
          <div class="invalid-feedback" v-for="error of v$.form.name.$errors" :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </div>

      <div class="row ms-2 mb-4">
        <div class="col-xl-3">Resize</div>
        <div class="col-lg-8">
          <div>
            <select v-model="form.mode" class="form-select" :class="{ 'is-invalid' : v$.form.mode.$error}">
              <option value="">Выберите один из вариантов</option>
              <option value="NONE">NONE</option>
              <option value="AUTOMATIC">AUTOMATIC</option>
              <option value="FIT">FIT</option>
              <option value="FIT_TO_WIDTH">FIT_TO_WIDTH</option>
              <option value="FIT_TO_HEIGHT">FIT_TO_HEIGHT</option>
            </select>
          </div>

          <div class="invalid-feedback" v-for="error of v$.form.mode.$errors" :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </div>

      <div class="row ms-2 mb-4" v-if="form.mode !== 'NONE'">
        <div class="col-xl-3">Width</div>
        <div class="col-lg-8">
          <input type="text"
                 class="form-control"
                 :class="{ 'is-invalid' : v$.form.width.$error}"
                 v-model="form.width">
          <div class="invalid-feedback" v-for="error of v$.form.width.$errors" :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </div>

      <div class="row ms-2 mb-4" v-if="form.mode !== 'NONE'">
        <div class="col-xl-3">Height</div>
        <div class="col-lg-8">
          <input type="text"
                 class="form-control"
                 :class="{ 'is-invalid' : v$.form.height.$error}"
                 v-model="form.height">
          <div class="invalid-feedback" v-for="error of v$.form.height.$errors" :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </div>



      <div class="row ms-2 mb-4" v-if="form.mode !== 'NONE'">
        <div class="col-xl-3">Quality</div>
        <div class="col-lg-8">
          <div>
            <select v-model="form.quality" class="form-select" :class="{ 'is-invalid' : v$.form.quality.$error}">
              <option value="">Выберите один из вариантов</option>
              <option value="HIGH">HIGH</option>
              <option value="MEDIUM">MEDIUM</option>
              <option value="LOW">LOW</option>
            </select>
          </div>

          <div class="invalid-feedback" v-for="error of v$.form.quality.$errors" :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </div>


      <div class="row ms-2 mb-4">
        <div class="col-xl-3">Description</div>
        <div class="col-lg-8">
           <textarea type="text"
                     class="form-control"
                     :class="{ 'is-invalid' : v$.form.description.$error}"
                     v-model="form.description"
                     rows="6"
           ></textarea>
          <div class="invalid-feedback" v-for="error of v$.form.description.$errors" :key="error.$uid">
            {{ error.$message }}
          </div>
        </div>
      </div>

      <div class="row ms-2 mb-4">
        <div class="col-xl-3"></div>
        <div class="col-lg-8">
          <button type="submit" class="btn btn-primary">Save</button>
        </div>
      </div>
    </form>

  </div>
</template>

<script>

import Http from "../../lib/Http";
import {required, requiredIf, maxLength, minLength, integer, helpers, minValue, maxValue} from '@vuelidate/validators'
import {useVuelidate} from '@vuelidate/core'
import HeadPanel from "../../components/HeadPanel";

export default {
  name: "Form",
  components: {HeadPanel},
  created() {
    this.fetch();
  },
  setup: () => ({v$: useVuelidate()}),
  data() {
    return {
      errors: [],
      form: {
        name: null,
        width: null,
        height: null,
        mode: 'NONE',
        quality: null,
        description: null
      },
    }
  },
  validations() {
    return {
      form: {
        name: {required, minLength: minLength(2), maxLength: maxLength(100), alpha: helpers.regex(/^[a-z0-9]*$/)},
        mode: {required},
        quality: {required: requiredIf(() => this.form.mode !== 'NONE')},
        width: {
          required: requiredIf(() => this.form.mode !== 'NONE'),
          integer,
          minValue: minValue(10),
          maxValue: maxValue(1600)
        },
        height: {
          required: requiredIf(() => this.form.mode !== 'NONE'),
          integer,
          minValue: minValue(10),
          maxValue: maxValue(1600)
        },
        description: {required, maxLength: maxLength(10000)}
      },
    }
  },
  methods: {
    fetch: function () {

      if (this.$route.params.id === undefined)
        return;

      Http.get('/v1/preset/' + this.$route.params.id)
          .then((res) => {
            this.form = res.content;
          });
    },
    submit() {
      const form = this.v$.form;

      form.$touch();

      if (form.$error) return

      const data = {
        name: this.form.name,
        width: this.form.width,
        height: this.form.height,
        quality: this.form.quality,
        mode: this.form.mode,
        description: this.form.description
      };

      let request = this.$route.params.id === undefined ?
          Http.post('/v1/preset', data) :
          Http.put('/v1/preset/' + this.$route.params.id, data);

      request.then(() => {
        this.$router.push('/preset')
      }).catch((e) => {
        this.errors = e.errors;
      });
    }
  }
}
</script>

<style scoped>

</style>